.modal-filter .modal-content {
  border: 1px solid #f4f4f4;
}

.modal-filter .modal-header {
  padding: 10px 1rem;
  border-bottom-color: #f4f4f4;
  background-color: #fafafa;
}

.modal-filter .modal-footer {
  border-top-color: #f4f4f4;
  background-color: #fafafa;
  padding: 10px 1rem;
}

.modal-filter .modal-header .close {
  outline: none;
}
