/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 20, 2019 */



@font-face {
    font-family: 'many_optionregular';
    src: url('manyoption-webfont.woff2') format('woff2'),
         url('manyoption-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}