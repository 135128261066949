.container-loading {
  position: absolute;
  z-index: 1111;
  display: grid;
  justify-content: center;
  align-content: center;
}

.section-loading {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(245, 245, 245, 0.5);
  top: 0;
  z-index: 11111;
}

.loading {
  position: relative;
  height: 60px;
  width: 60px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: none;
}

.loading-img {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  /* left: 0; */
  /* right: 0; */
  /* top: 25%; */
  /* bottom: 25%; */
}
