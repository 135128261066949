.btn-space {
  margin: 0px 3px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group > button,
.button-group > a {
  margin-bottom: 5px;
  margin-right: 5px;
}

.button-group button:last-child,
.button-group a:last-child {
  margin-right: 0px;
}
.button-group .btn-light {
  border-color: gray;
}

.option-button {
  border: 1px dashed gray;
  margin-bottom: 10px;
  padding: 5px;
}

.option-button label {
  margin-left: 20px;
}
