.mo-form.fieldset {
  border: 1px solid #dddddd;
  display: inline-block;
  font-size: 14px;
  padding: 0 1em;
  width: 100%;
  margin-bottom: 1rem;
}

.mo-form.fieldset legend {
  margin-bottom: 10px;
  width: auto;
  font-size: 0.9rem;
}
