.modal-preview {
  position: relative;
}

.modal-preview .modal-header {
  border: none;
  position: absolute;
  right: 16px;
  z-index: 1;
}

.modal-preview .close {
  padding: 0px;
  background-color: #f86c6b;
  border-color: #f86c6b;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  outline: none;
  opacity: 1;
}

.modal-preview .close span {
  color: #ffffff;
}

.modal-preview .modal-title {
  display: none;
}

.section-image-popover {
  position: relative;
}

.section-image-popover > .section-button > .btn {
  border-radius: 50px;
}

.section-image-popover .section-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}

.section-image-popover > .section-inner-modal {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
}
